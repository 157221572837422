import React from "react";

// component styling
import injectSheet from "react-jss";

//component import
import PageTitle from "./common/PageTitle";

// //asset import
import Loader from "../../images/loaders/oval.svg";

const styles = theme => ({
  videoWrapper: {
    position: "relative",
    paddingBottom: "56.25%",
    paddingTop: 25,
    height: 0
  },
  iframe: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%"
  }
});

const VIDEO_GALLERY_TEMPLATE = ({ classes, page, activeBreadCrumb }) => {
  let pageContent = (
    <div className="text-center">
      <object type="image/svg+xml" data={Loader} width="70">
        Loading...
      </object>
    </div>
  );

  if (page.data.length) {
    const videoList = page.data.map((video, i) => {
      if (video.url) {
        const url = video.url.replace("watch?v=", "embed/");
        return (
          <div className="col-md-6 col-lg-4" key={i}>
            <div className={classes.videoWrapper}>
              <iframe
                width="560"
                height="315"
                title="Video Player"
                className={classes.iframe}
                src={url}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="text-center mt-2">
              <strong>{video.title}</strong>
            </div>
          </div>
        );
      }
    });

    pageContent = <div className="row">{videoList}</div>;
  }

  return (
    <React.Fragment>
      <PageTitle title={activeBreadCrumb.label} />
      {pageContent}
    </React.Fragment>
  );
};

export default injectSheet(styles)(VIDEO_GALLERY_TEMPLATE);
