import * as template from "../templates/templateTypes";

import Home from "../home/Home";
import Page from "../page/Page";
import Contact from "../contact/Contact";

export default [
  {
    path: "/",
    component: Home,
    exact: true
  },
  {
    path: "/admin",
    component: () => {
      window.location.href = "https://uniquecoms.com/admin";
      return null;
    },
    exact: true
  },
  // {
  //   path: "/:slug/:slug",
  //   component: Page,
  //   exact: true,
  //   template: template.LIST_TEMPLATE
  // },
  // {
  //   path: "/about/:slug",
  //   component: Page,
  //   template: template.DETAIL_TEMPLATE
  // },
  {
    path: "/program",
    component: Page,
    exact: true,
    template: template.LIST_TEMPLATE
  },
  {
    path: "/program/:slug",
    component: Page,
    template: template.DETAIL_TEMPLATE
  },
  // {
  //   path: "/admission",
  //   component: Page,
  //   exact: true,
  //   template: template.LIST_TEMPLATE
  // },
  // {
  //   path: "/admission/:slug",
  //   component: Page,
  //   template: template.DETAIL_TEMPLATE
  // },
  // {
  //   path: "/facility",
  //   component: Page,
  //   exact: true,
  //   template: template.LIST_TEMPLATE
  // },
  // {
  //   path: "/facility/:slug",
  //   component: Page,
  //   template: template.DETAIL_TEMPLATE
  // },
  {
    path: "/gallery/photo",
    component: Page,
    exact: true,
    template: template.PHOTO_GALLERY_TEMPLATE
  },
  {
    path: "/gallery/video",
    component: Page,
    exact: true,
    template: template.VIDEO_GALLERY_TEMPLATE
  },
  // {
  //   path: "/download",
  //   component: Page,
  //   exact: true,
  //   template: template.LIST_TEMPLATE
  // },
  // {
  //   path: "/download/:slug",
  //   component: Page,
  //   template: template.DETAIL_TEMPLATE
  // },
  {
    path: "/event",
    component: Page,
    exact: true,
    template: template.DETAIL_LIST_TEMPLATE
  },
  // {
  //   path: "/event/:slug",
  //   component: Page,
  //   template: template.DETAIL_TEMPLATE
  // },
  {
    path: "/notice",
    component: Page,
    exact: true,
    template: template.DETAIL_LIST_TEMPLATE
  },
  // {
  //   path: "/notice/:slug",
  //   component: Page,
  //   template: template.DETAIL_TEMPLATE
  // },
  {
    path: "/contact",
    component: Contact,
    template: template.CONTACT_TEMPLATE
  },
  {
    path: "/downloads/student-records",
    component: Page,
    template: template.STUDENT_RECORD_TEMPLATE
  },
  {
    path: "/:slug/:slug?",
    component: Page,
    exact: true,
    template: template.DETAIL_TEMPLATE
  }
];
