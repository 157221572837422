import React from "react";

//component import
import PageTitle from "./common/PageTitle";
import SidebarNav from "./common/SidebarNav";

//asset import
// import Loader from "../../images/loaders/oval.svg";

const LIST_TEMPLATE = ({
  page,
  breadCrumb,
  activeBreadCrumb,
  setActiveBreadCrumb
}) => {
  const data = page.data;

  let pageContent = (
    <div className="text-center">
      {/* <object type="image/svg+xml" data={Loader} class="logo" width="70">
        Loading...
      </object> */}
    </div>
  );

  if (data.length) {
    pageContent = data.map((singleData, i) => (
      <div key={i}>
        {i > 0 ? <hr className="my-5" /> : null}
        <PageTitle
          title={singleData.title}
          link={"/" + breadCrumb.slug + "/" + singleData.slug}
        />
        {/* <div
          dangerouslySetInnerHTML={{
            __html: singleData.description.substr(0, 200)
          }}
        /> */}
      </div>
    ));
  }

  return (
    <div className="row">
      <div className="col-md">
        {pageContent}
        {/* {data.file ? (
              <a
                href={page.resource_path + data.file}
                className="btn btn-primary"
                target="_blank"
              >
                <i className="fa fa-download mr-1"></i> {data.title}
              </a>
            ) : null} */}
      </div>
      <SidebarNav
        breadCrumb={breadCrumb}
        activeBreadCrumb={activeBreadCrumb}
        setActiveBreadCrumb={setActiveBreadCrumb}
      />
    </div>
  );
};

export default LIST_TEMPLATE;
