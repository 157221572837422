import React from "react";
import { Link } from "react-router-dom";

import { ListGroup, ListGroupItem } from "reactstrap";

// component styling
import injectSheet from "react-jss";

const styles = theme => ({
  aside: theme.page.aside,
  activeList: {
    "&>.active": {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main
    }
  }
});

const SidebarNav = ({
  classes,
  breadCrumb,
  activeBreadCrumb,
  setActiveBreadCrumb
}) => {
  const breadCrumbList = breadCrumb.submenu ? (
    <ListGroup className={classes.activeList}>
      {breadCrumb.submenu.map(navLink => (
        <ListGroupItem
          key={navLink.slug}
          active={navLink.slug === activeBreadCrumb.slug}
          tag={Link}
          to={"/" + breadCrumb.slug + "/" + navLink.slug}
          action
          onClick={() => setActiveBreadCrumb(navLink.label, navLink.slug)}
        >
          {navLink.label}
        </ListGroupItem>
      ))}
    </ListGroup>
  ) : (
    ""
  );

  return (
    <aside className={`col-md aside ${classes.aside}`}>
      <h5>{breadCrumb.label}</h5>
      {breadCrumbList}
    </aside>
  );
};

export default injectSheet(styles)(SidebarNav);
