export const createTheme = theme => {
  theme.button.primary = {
    color: "#fff",
    backgroundColor: theme.palette.primary.main,
    hoverColor: "#fff",
    hoverBackgroundColor: theme.palette.primary.hover
  };

  theme.topHeader = {
    fontSize: theme.typography.fontSize - 1,
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    hoverColor: "#fff",
    progressBar: {
      height: 2,
      "&>div": {
        backgroundColor: theme.palette.primary.hover
      }
    }
  };

  theme.header = {
    background: theme.palette.primary.main,
    headerLogoContainer: {
      paddingTop: 10,
      paddingBottom: 10
    },
    headerLogo: {
      lgHeight: 60,
      smHeight: 40
    },
    navBar: {
      alignment: "center",
      minHeight: 80
    },
    navLink: {
      dropdownIcon: "'\\f107'",
      color: "#000",
      hoverColor: theme.palette.primary.main,
      paddingLeft: 10,
      paddingRight: 10,
      fontWeight: 500
    },
    navDropdown: {
      paddingTop: 10,
      paddingBottom: 0,
      borderRadius: 0,
      minWidth: 200,
      borderTopWidth: 3,
      borderTopColor: theme.palette.primary.main
    },
    navDropdownItem: {
      color: "#000",
      backgroundColor: "#fff",
      paddingTop: 10,
      paddingBottom: 10,
      hoverColor: theme.palette.primary.main,
      hoverBackgroundColor: "#eee"
    }
  };

  theme.slider = {
    height: 480,
    captionContainer: {
      itemAlign: "left",
      textAlign: "left"
    },
    caption: {
      heading: {
        // fontFamily: "'Bree Serif', serif",
        fontSize: 45,
        lineHeight: "50px",
        marginBottom: 20,
        fontWeight: 500,
      },
      button: {
        color: "#fff",
        backgroundColor: theme.palette.primary.main,
        fontSize: 16,
        hoverColor: "#fff",
        hoverBackgroundColor: theme.palette.primary.hover,
        paddingX: 16,
        paddingY: 10
      }
    }
  };

  theme.scrollingNotice = {
    height: 50,
    fontSize: theme.typography.fontSize,
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    speed: 20,
    label: {
      backgroundColor: theme.palette.primary.hover,
      color: "#fff",
      fontSize: 18,
      fontWeight: 600
    }
  };
  const scrollingDuration = 40 - theme.scrollingNotice.speed;

  theme.section = {
    paddingTop: 70,
    paddingBottom: 70,
    heading: {
      fontSize: 20,
      fontWeight: 700,
      marginBottom: 20,
      textTransform: "uppercase",
      color: "#000"
    },
    introSection: {
      heading: {
        fontSize: 35,
        color: "#c00"
      },
      media: true,
      mediaPosition: "right",
      textAlign: "left"
    },
    courseSection: {
      columns: 3,
      backgroundColor: theme.palette.grey.main,
      heading: {
        textAlign: "center"
      },
      card: {
        contentJustify: "center",
        main: {
          borderRadius: 0,
          borderColor: "rgba(0,0,0,.125)",
          boxShadow: "none",
          textAlign: "center"
        },
        title: {
          fontSize: 16,
          color: "#000",
          fontWeight: "700"
        },
        imageContainer: {
          borderRadius: "0",
          borderStyle: "solid",
          borderColor: "#f90",
          borderWidth: 0,
          overflow: "hidden",
          width: "100%",
          height: 175,
          margin: "0 auto"
        },
        image: {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          height: 175,
          width: "auto",
          marginLeft: "50%",
          transform: "translateX(-50%)"
        }
      }
    },
    newsSection: {
      main: { backgroundColor: theme.palette.grey.main },
      heading: {
        textAlign: "center"
      },
      card: {
        main: {
          borderRadius: 0,
          borderColor: "rgba(0,0,0,.125)",
          boxShadow: "none",
          textAlign: "left"
        },
        title: {
          fontSize: 16,
          color: theme.palette.primary.main,
          fontWeight: "700"
        },
        imageContainer: {
          borderRadius: "0",
          borderStyle: "solid",
          borderColor: "#f90",
          borderWidth: 0,
          overflow: "hidden",
          width: "100%",
          height: 200,
          margin: "0 auto",
          display: "flex",
        },
        image: {
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          height: "100%",
          width: "100%",
          objectFit: "cover",
          // marginLeft: "50%",
          // transform: "translateX(-50%)"
        },
        cardText: {
          height: 72
        }
      }
    },
    sponsorSection: {
      main: {
        paddingTop: 20,
        paddingBottom: 20
      }
    },
    testimonialSection: {
      main: { backgroundColor: theme.palette.grey.main },
      heading: {
        textAlign: "center"
      },
      card: {
        main: {
          borderRadius: 0,
          borderColor: "rgba(0,0,0,.125)",
          boxShadow: "none",
          textAlign: "center",
          margin: "110px 15px 0"
        },
        title: {
          fontSize: 16,
          color: theme.palette.primary.main,
          fontWeight: "700"
        },
        imageContainer: {
          borderRadius: "50%",
          borderStyle: "solid",
          borderColor: theme.palette.secondary.main,
          borderWidth: 5,
          overflow: "hidden",
          width: 120,
          height: 120,
          margin: "0 auto",
          marginTop: -70
        },
        cardText: {
          minHeight: 96
        },
        cardBody: {
          padding: "10px 20px 20px"
        },
        image: {
          // borderTopLeftRadius: 0,
          // borderTopRightRadius: 0,
          // height: 175,
          // width: "auto",
          // marginLeft: "50%",
          // transform: "translateX(-50%)"
        }
      }
    }
  };

  theme.photoGallery = {
    columns: 3,
    heading: {
      textAlign: "center"
    },
    card: {
      main: {
        borderRadius: 0,
        borderColor: "rgba(0,0,0,.125)",
        boxShadow: "none",
        cursor: "pointer",
        textAlign: "center",
        "&:after": {
          content: "",
          background: "#f90",
          position: "absolute",
          top: "100%",
          left: 0,
          width: "100%",
          height: 2
        }
      },
      title: {
        fontSize: 16,
        color: "#fff",
        fontWeight: "700",
        marginBottom: 0
      },
      imageContainer: {
        borderRadius: "0",
        borderStyle: "solid",
        borderColor: "#f90",
        borderWidth: 0,
        overflow: "hidden",
        width: "100%",
        height: 175,
        margin: "0 auto"
      },
      image: {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
        margin: "0 auto"
      },
      cardBody: {
        background: "#000",
        padding: 15
      }
    }
  };

  theme.footer = {
    main: {
      background: theme.palette.primary.main,
      paddingTop: 70,
      paddingBottom: 30,
      color: "#fff"
    },
    footerLogoContainer: {
      paddingTop: 0,
      paddingBottom: 0
    },
    footerLogo: {
      height: 60,
      // filter: "brightness(0) invert(1)",
      marginBottom: 20
    },
    heading: {
      fontSize: 15,
      marginBottom: 30,
      textTransform: "uppercase"
    },
    listItem: {
      marginBottom: 10
    },
    link: {
      color: "#fff",
      "&:hover": {
        color: "#eee",
        textDecoration: "none"
      }
    },
    listHeading: {
      fontWeight: 600,
      fontSize: 15
    }
  };

  theme.page = {
    main: {
      paddingTop: 40,
      paddingBottom: 40,
      "& span": {
        fontFamily: theme.typography.fontFamily + " !important",
        fontSize: theme.typography.fontSize + "px !important"
      }
    },
    pageTitle: {
      color: theme.palette.primary.main,
      marginBottom: 30
    },
    aside: {
      // borderLeft: "1px solid #ddd"
    },
    asideWidth: 300
  };

  var globalCSS = `
    body {
      font-family:${theme.typography.fontFamily};
      font-size:${theme.typography.fontSize}px;
      line-height:${theme.typography.lineHeight}px;
    }
    ::selection{
      color:#fff;
      background:${theme.palette.primary.main}
    }
    .btn{
      border-radius:${theme.utils.borderRadius}px;
      font-size:${theme.typography.fontSize}px;
    }   
    .btn-primary{
      color:${theme.button.primary.color};
      background-color:${theme.button.primary.backgroundColor};
      border-color:${theme.button.primary.backgroundColor};
    } 
    .btn-primary:hover,.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
      color:${theme.button.primary.hoverColor};
      background-color:${theme.button.primary.hoverBackgroundColor};
      border-color:${theme.button.primary.hoverBackgroundColor};
    } 
    .line-height-normal{
      line-height:${theme.typography.lineHeight}px !important;
    }
    .form-control{font-size: ${theme.typography.fontSize}px; border-radius: ${theme.utils.borderRadius}px}
    h1{
      font-size:${theme.typography.h1.fontSize}px;
      font-weight: ${theme.typography.h1.fontWeight};
    }

    .dropdown-item{
      font-size:${theme.typography.fontSize}px;
    }
    .dropdown-toggle::after{
      content:${theme.header.navLink.dropdownIcon};
      font:normal normal normal 14px/1 FontAwesome;
      border:none;
      vertical-align: initial;
    }
    .navbar-light .navbar-nav .nav-link{
      color:${theme.header.navLink.color};
    }
    .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover{
      color:${theme.header.navLink.hoverColor};
    }
    .navbar-expand-md .navbar-nav .nav-link{
      font-weight:${theme.header.navLink.fontWeight};
    }
    .navbar-nav .dropdown-menu{
      padding-top:${theme.header.navDropdown.paddingTop}px;
      padding-bottom:${theme.header.navDropdown.paddingBottom}px;
      min-width:${theme.header.navDropdown.minWidth}px;
      border-radius:${theme.header.navDropdown.borderRadius}px;
    }
    .navbar-nav .dropdown-toggle::after{
      margin-top: 5px;
      float: right;
    }

    .navbar-nav .dropdown-item{
      padding-top:${theme.header.navDropdownItem.paddingTop}px;
      padding-bottom:${theme.header.navDropdownItem.paddingBottom}px;
      color:${theme.header.navDropdownItem.color};
      background-color:${theme.header.navDropdownItem.backgroundColor};
    }
    .navbar-nav .dropdown-item:first-child{
      border-top-style:solid;
      border-top-width:${theme.header.navDropdown.borderTopWidth}px;
      border-top-color:${theme.header.navDropdown.borderTopColor};
    }
    .navbar-nav .dropdown-item:hover{
      color:${theme.header.navDropdownItem.hoverColor};
      background-color:${theme.header.navDropdownItem.hoverBackgroundColor};
    }

    .main-slider li.alice-carousel__stage-item{
      height:${theme.slider.height}px;
    }
    .alice-carousel__dots-item:hover, .alice-carousel__dots-item.__active{
      background-color:${theme.palette.primary.main} !important;
    }

    .notice-text{
      position: absolute;
      width: 100%;
      height: 100%;
      -moz-transform:translateX(100%);
      -webkit-transform:translateX(100%);	
      transform:translateX(100%);
      -moz-animation: scroll ${scrollingDuration}s linear infinite;
      -webkit-animation: scroll ${scrollingDuration}s linear infinite;
      animation: scroll ${scrollingDuration}s linear infinite;
    }

    .notice-text:hover {
      -moz-animation-play-state: paused;
      -webkit-animation-play-state: paused;
      animation-play-state: paused;
    }

    @-moz-keyframes scroll {
    0%   { -moz-transform: translateX(100%); }
    100% { -moz-transform: translateX(-100%); }
    }
    @-webkit-keyframes scroll {
    0%   { -webkit-transform: translateX(100%); }
    100% { -webkit-transform: translateX(-100%); }
    }
    @keyframes scroll {
    0%   { 
    -moz-transform: translateX(100%); /* Firefox bug fix */
    -webkit-transform: translateX(100%); /* Firefox bug fix */
    transform: translateX(100%); 		
    }
    100% { 
    -moz-transform: translateX(-100%); /* Firefox bug fix */
    -webkit-transform: translateX(-100%); /* Firefox bug fix */
    transform: translateX(-100%); 
    }
    }
    .notice-text:hover {
    -moz-animation-play-state: paused;
    -webkit-animation-play-state: paused;
    animation-play-state: paused;
    }

    .content{
      padding-top:${theme.section.paddingTop}px;
      padding-bottom:${theme.section.paddingBottom}px;
    }

    .section-heading{
      font-size: ${theme.section.heading.fontSize}px;
      font-weight: ${theme.section.heading.fontWeight};
      margin-bottom: ${theme.section.heading.marginBottom}px;
      text-transform: ${theme.section.heading.textTransform};
      color: ${theme.section.heading.color};
    }

    .with-overlay:after{
      background-color: ${theme.utils.overlayBackgroundColor}
    }

    footer h4{position:relative}
    footer h4:after {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 50px;
      height: 1px;
      background: #fff;
    }

    @media (min-width: 768px){
      .navbar-expand-md .navbar-nav .nav-link {
        padding-left:8px;
        padding-right:8px;
      }
      .aside{max-width: ${theme.page.asideWidth}px}
    }

    .logo-text{
      max-width: 170px;
      white-space: normal;
      display: inline-block;
      color: #fff;
      vertical-align: middle;
      margin-left: 5px;
    }

    @media (max-width: 768px){
      .section-heading{margin-bottom: 10px;}
    }
  `;

  switch (theme.webId) {
    case 24:
      globalCSS += `
        .header{position: absolute; top:0; left:0; width: 100%; background: #0000004d; z-index:11; 
          background: linear-gradient(180deg, #0000008c, transparent); min-height: 170px; box-shadow: none !important;}
        .header>div{background: none !important}
        .main-slider li.alice-carousel__stage-item{height:100vh}
        .navbar-light .navbar-nav .nav-link, .navbar-light .navbar-nav .show>.nav-link{color: #fff;}
        .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover{color: #fff;}
        .navbar-nav .dropdown-item{background: #00000087; color: #fff; padding-top: 7px; padding-bottom: 7px; max-width: 300px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}
        .navbar-nav .dropdown-item:first-child{border-top-color: transparent;}
        .navbar-nav .dropdown-item:hover{background: #00000087}

        // .heading-ue{color: #3b5998}
        .heading-fb{color: #3b5998}
        .heading-yt{color: #ff0000}
        
        .page-header{height:300px !important}  
        .page-header.with-overlay:after {
            background-color: rgba(0,0,0,0.4);
        }

        .news-section{background: #f7f7f7}
        .news-section .card-body{position:absolute; bottom:0; left:0; width:100%; background: linear-gradient(180deg,transparent,rgba(0,0,0,0.7))}
        .news-section .card>a>div{height:300px;}
        .news-section .card-title, .news-section .card-text{color: #fff}

        .left-carousel-control, .right-carousel-control, .alice-carousel__dots {
            display: none;
        }

        @media(max-width: 1170px){
          .main-slider li.alice-carousel__stage-item{height:540px}     
          .content{padding-top: 50px; padding-bottom: 50px;}     
        }

        @media(max-width:992px){
          .logo-text{display: none;}
          .main-slider li.alice-carousel__stage-item{height:420px} 
          .slider-content h1{font-size:30px; margin-bottom: 0;}  
          .content{padding-top: 35px; padding-bottom: 35px;}     
        }

        @media(max-width:767px){
          .main-slider li.alice-carousel__stage-item{height:480px}
          // .header>div{display:none;}
          .main-slider .slider-content>div{align-items: flex-end}
          .navbar-light .navbar-toggler-icon{background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");}
          .content{padding-top: 20px; padding-bottom: 20px;}
          .navbar-collapse {
              background: #000;
              padding: 0 15px;
          }
        }
      `;
      break;
  }

  //append style after bootstrap css in head
  var oldStyle = document.querySelectorAll("style[data-meta='global']")[0];
  var style = document.createElement("style");
  if (oldStyle) {
    style = oldStyle;
  } else {
    var style = document.createElement("style");
    style.setAttribute("data-meta", "global");
    style.type = "text/css";
  }

  if (style.styleSheet) {
    // This is required for IE8 and below.
    style.styleSheet.cssText = globalCSS;
  } else {
    style.appendChild(document.createTextNode(globalCSS));
  }

  // var referenceNode = document.querySelector("style");
  // var referenceNode = document.querySelector("title+link[rel='stylesheet']");
  
  var referenceNode = window.location.hostname === "localhost" ?  document.querySelector("style")
      : document.querySelector("title+link[rel='stylesheet']");
  if (referenceNode) {
    referenceNode.parentNode.insertBefore(style, referenceNode.nextSibling);
  }

  //return theme parameters to be used by components
  return theme;
};
