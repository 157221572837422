import React from "react";

//component import
// import PageTitle from "./common/PageTitle";
// import SidebarNav from "./common/SidebarNav";

//asset import
// import Loader from "../../images/loaders/oval.svg";

const LIST_TEMPLATE = ({ page }) => {
  const data = page.data;

  let pageContent = (
    <div className="text-center">
      {/* <object type="image/svg+xml" data={Loader} class="logo" width="70">
        Loading...
      </object> */}
    </div>
  );
  if (data.length) {
    pageContent = data.map((singleData, i) => (
      <div key={i}>
        {i > 0 ? <hr className="my-5" /> : null}
        <h4>{singleData.title}</h4>
        <div className="row">
          <div className="col-md-9">
            <div
              dangerouslySetInnerHTML={{
                __html: singleData.description
                  ? singleData.description.substr(0, 200)
                  : ""
              }}
            />
          </div>
          <div className="col-md-3 text-right">
            {singleData.file ? (
              <a
                href={page.resource_path + data.file}
                className="btn btn-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-download mr-1"></i> Download
              </a>
            ) : null}
          </div>
        </div>
      </div>
    ));
  }

  return (
    <div className="row">
      <div className="col-md">{pageContent}</div>
      {/* <SidebarNav
        breadCrumb={breadCrumb}
        activeBreadCrumb={activeBreadCrumb}
        setActiveBreadCrumb={setActiveBreadCrumb}
      /> */}
    </div>
  );
};

export default LIST_TEMPLATE;
