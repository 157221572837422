import React, { Component } from "react";
import { connect } from "react-redux";

// template data (will be provided with backend in future)
import routes from "../route/routes";

//action import
import {
  getPageData,
  getBreadCrumbList,
  setPageData,
  setActiveBreadCrumb
} from "../../actions/frontAction";

// component styling
import injectSheet from "react-jss";

// component import
import Layout from "../layout/Layout";
import Template from "../templates/Template";
import MyBreadCrumb from "../common/BreadCrumb";
import PageHeader from "../common/PageHeader";

//asset
import slide2 from "../../images/bannar.jpg";

const styles = theme => ({
  content: theme.page.main
});

class Page extends Component {
  render() {
    const { theme, classes, profile } = this.props;
    const classConstant = theme.classConstant;

    const {
      page,
      breadCrumb,
      activeBreadCrumb,
      setActiveBreadCrumb
    } = this.props;
    let templateName = null;

    const path = this.props.match.path;
    routes.map(route => {
      if (route.path === path) {
        templateName = route.template;
      }
    });

    return (
      <Layout>
        {profile.data.banner_image ? (
          <PageHeader
            image={profile.resource_path + profile.data.banner_image}
          />
        ) : null}
        <MyBreadCrumb
          breadCrumb={breadCrumb}
          activeBreadCrumb={activeBreadCrumb}
        />
        <section className={`content ${classes.content}`}>
          <div className={classConstant.CONTAINER_CLASS}>
            <Template
              name={templateName}
              page={page}
              theme={theme}
              breadCrumb={breadCrumb}
              activeBreadCrumb={activeBreadCrumb}
              setActiveBreadCrumb={setActiveBreadCrumb}
            />
          </div>
        </section>
      </Layout>
    );
  }

  componentWillMount() {
    this.props.getBreadCrumbList();
    this.props.getPageData(this.props.match.url);
  }

  componentWillUpdate(nextProps) {
    if (this.props.match.url !== nextProps.match.url) {
      this.props.getBreadCrumbList();
      this.props.getPageData(nextProps.match.url);
    }
  }
}

function mapStateToProps(state) {
  return {
    breadCrumb: state.breadCrumb,
    activeBreadCrumb: state.activeBreadCrumb,
    page: state.page,
    profile: state.layout.profile
  };
}

export default injectSheet(styles)(
  connect(mapStateToProps, {
    getPageData,
    getBreadCrumbList,
    setPageData,
    setActiveBreadCrumb
  })(Page)
);
