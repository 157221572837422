import * as types from "./actionTypes";
import SchoolApi from "../services/SchoolApi";

//pushing routes for redirecting to pages
import { push } from "react-router-redux";

// import { history } from "history";

//method that accepts theme data and generates theme object
import generateTheme from "../theme/Theme";

//pace page loader
import NProgress from "nprogress";
import "../../node_modules/nprogress/nprogress.css";
NProgress.configure({ easing: "ease", speed: 500 });

const schoolApi = new SchoolApi();

function getBreadCrumb(menu, menuItem) {
  //console.log("get breadcrumb", menu, menuItem);
  return new Promise(resolve => {
    menuItem.forEach(function (obj) {
      if (obj.slug === menu) {
        resolve(obj);
      }
    });
  });
}

function breadCrumbList(dispatch, menuItem) {
  const urlArr = window.location.hash.substr(1).split("/");
  // const urlArr = window.location.pathname.split("/");
  const menu = urlArr[1];
  getBreadCrumb(menu, menuItem).then(menuObject => {
    dispatch(breadCrumbData(menuObject));
    const subMenu = urlArr[2];
    if (subMenu && menuObject.submenu) {
      getBreadCrumb(subMenu, menuObject.submenu).then(subMenuObject => {
        dispatch(activeBreadCrumb(subMenuObject));
      });
    } else {
      dispatch(activeBreadCrumb(null));
    }
  });
}

// layout data
export const LayoutData = data => {
  return { type: types.FETCH_LAYOUT_DATA, payload: data };
};
export const breadCrumbData = data => {
  return { type: types.FETCH_BREADCRUMB_DATA, payload: data };
};
export const activeBreadCrumb = data => {
  return { type: types.ACTIVE_BREADCRUMB, payload: data };
};
export function getLayoutData() {
  const path = "/layout";
  return function (dispatch) {
    return schoolApi
      .get(path)
      .then(data => {
        // console.log("DATA:::", data.body);
        breadCrumbList(dispatch, data.body.menu);

        data.body.theme.webId = data.body.profile.data.id;

        //genrate theme based on website theme data
        data.body.theme = generateTheme(data.body.theme);

        data.body.isLoaded = true;
        dispatch(LayoutData(data.body));
      })
      .catch(err => dispatch(push("/502")));
  };
}

export function getBreadCrumbList() {
  return function (dispatch, getState) {
    breadCrumbList(dispatch, getState().layout.menu);
  };
}

export function setActiveBreadCrumb(label, slug) {
  return function (dispatch) {
    label && slug
      ? dispatch(activeBreadCrumb({ label: label, slug: slug }))
      : dispatch(activeBreadCrumb(null));
  };
}

// homepage data
export const HomePageData = data => {
  return { type: types.FETCH_HOMEPAGE_DATA, payload: data };
};
export function getHomePageData() {
  const path = "/home";
  return function (dispatch) {
    return schoolApi
      .get(path)
      .then(data => {
        if (data.body) dispatch(HomePageData(data.body));
      })
      .catch(err => dispatch(push("/502")));
  };
}

//get first page data of submenu of non content page(only menu)
function getFirstPageData(menu, slug) {
  return new Promise((resolve, reject) => {
    menu.forEach(function (obj, i) {
      if (obj.slug === slug) {
        if (obj.submenu && obj.submenu.length) {
          resolve(obj.submenu[0].slug);
        }
      }
    });
    // console.log("test");
  });
}

// page data
export const PageData = data => {
  return { type: types.FETCH_PAGE_DATA, payload: data };
};
export function getPageData(url) {
  NProgress.start();
  return function (dispatch, getState) {
    //dispatch(PageData({ data: [] }));
    return schoolApi
      .get(url)
      .then(response => {
        const pageData = response.body.data;
        const menu = getState().layout.menu;
        // console.log(pageData);
        if (pageData.length) {
          //check if is page is just menu or content page
          if (pageData[0].is_menu) {
            //if menu, redirect to first submenu page
            const menuSlug = pageData[0].slug;

            getFirstPageData(menu, menuSlug).then(submenuSlug => {
              // console.log("wowowow", menuSlug, submenuSlug);
              // setActiveBreadCrumb(menuSlug, submenuSlug);
              // dispatch(
              //   activeBreadCrumb({ label: menuSlug, slug: submenuSlug })
              // );
              dispatch(push(`/${menuSlug}/${submenuSlug}`));
              // dispatch(getPageData(`/${menuSlug}/${submenuSlug}`));
            });
          } else {
            // if not menu, dispatch to detail page or no data page if data is null
            // if (
            //   pageData[0].summary ||
            //   pageData[0].description ||
            //   pageData[0].image ||
            //   pageData[0].url
            // ) {
            //   dispatch(PageData(response.body));
            // } else {
            //   dispatch(PageData(response.body));
            // }
            dispatch(PageData(response.body));
          }
        } else dispatch(push("/404"));
        NProgress.done();
      })
      .catch(error => dispatch(push("/502")));
  };
}
export function setPageData(data) {
  return function (dispatch) {
    dispatch(PageData({ data }));
  };
}

//contact message
export function sendContactMessage(formData) {
  return () => {
    return new Promise(resolve => {
      resolve(schoolApi.create("/messageus/submit", formData));
    });
  };
}

// error handling
export const ErrorData = data => {
  return { type: types.SET_ERROR, payload: data };
};
export function setError(data) {
  return function (dispatch) {
    dispatch(ErrorData({ data }));
  };
}
