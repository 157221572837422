import React from "react";

//component import
import PageTitle from "./common/PageTitle";
import SidebarNav from "./common/SidebarNav";

//asset import
// import Loader from "../../images/loaders/oval.svg";

// let pageContent = (
//   <div className="text-center">
//     <object type="image/svg+xml" data={Loader} class="logo" width="70">
//       Loading...
//     </object>
//   </div>
// );

const DETAIL_TEMPLATE = ({
  page,
  theme,
  breadCrumb,
  activeBreadCrumb,
  setActiveBreadCrumb
}) => {
  let data = null;
  let pageContent = null;
  if (page.data.length) {
    data = page.data[0];

    const image = data.image ? (
      <a
        href={page.resource_path + data.image}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="w-100 mb-3"
          src={page.resource_path + data.image}
          alt={data.title}
        />
      </a>
    ) : null;

    if (data && data.description) {
      pageContent = (
        <React.Fragment>
          {image}
          <div
            className="text-justify"
            dangerouslySetInnerHTML={{
              __html: data.description
            }}
          />
        </React.Fragment>
      );
    } else {
      pageContent = image;
    }
  }

  return (
    <React.Fragment>
      {data ? (
        <div className="row">
          <div className="col-md">
            <PageTitle
              title={theme.language == "np" ? data.title_np : data.title}
            />
            {pageContent}
            {data.file ? (
              <a
                href={page.resource_path + data.file}
                className="btn btn-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-download mr-1"></i> {data.title}
              </a>
            ) : null}
          </div>
          <SidebarNav
            breadCrumb={breadCrumb}
            activeBreadCrumb={activeBreadCrumb}
            setActiveBreadCrumb={setActiveBreadCrumb}
          />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default DETAIL_TEMPLATE;
