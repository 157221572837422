import React, { useState } from "react";
import { Link } from "react-router-dom";

//reactstrap import
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";

// react-jss import
import injectSheet from "react-jss";

// component import
import TopHeader from "./TopHeader";

//asset import
import MyUncontrolledDropdown from "../../dropdown/MyUncontrolledDropdown";

const style = theme => ({
  headerLogoContainer: {
    paddingTop: theme.header.headerLogoContainer.paddingTop,
    paddingBottom: theme.header.headerLogoContainer.paddingBottom
  },
  headerLogo: {
    height: theme.header.headerLogo.lgHeight
  },
  navBar: {
    minHeight: theme.header.navBar.minHeight,
    alignItems: theme.header.navBar.alignment
  },
  navLink: {
    // paddingLeft: theme.header.navLink.paddingLeft,
    // paddingRight: theme.header.navLink.paddingLeft
  }
});

const Header = ({ classes, theme, layout }) => {
  const [isOpen, toggleOpen] = useState(false);
  const classConstant = theme.classConstant;
  // console.log(layout.profile);
  const logoText =
    layout.profile.data.id == 24 ? (
      // <span className="logo-text theme2">{layout.profile.data.name}</span>
      <span className="logo-text">{layout.profile.data.name}</span>
    ) : null;

  return (
    <div className="header">
      <TopHeader layout={layout} />
      <Navbar
        light
        expand="md"
        className={`py-lg-0 ${classes.navBar} ${classConstant.CONTAINER_CLASS}`}
      >
        <NavbarBrand tag={Link} to="/" className={classes.headerLogoContainer}>
          {layout.profile.data.logo ? (
            <img
              src={layout.profile.resource_path + layout.profile.data.logo}
              className={`w-auto ${classes.headerLogo}`}
              alt={`${layout.profile.data.name} logo`}
            />
          ) : null}
          {logoText}
        </NavbarBrand>
        <NavbarToggler
          onClick={() => {
            toggleOpen(!isOpen);
          }}
        />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            {layout.menu.map((navItem, i) => {
              if (navItem.submenu && navItem.submenu.length) {
                return (
                  <MyUncontrolledDropdown
                    navItem={navItem}
                    key={navItem.slug + i}
                  />
                );
              } else
                return (
                  <NavItem key={navItem.slug}>
                    <NavLink tag={Link} to={"/" + navItem.slug}>
                      {navItem.label}
                    </NavLink>
                  </NavItem>
                );
            })}
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default injectSheet(style)(Header);
