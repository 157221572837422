import React from 'react';
import { Link } from 'react-router-dom';

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

// react-jss import
import injectSheet from 'react-jss';

// font awesome import
import FA from 'react-fontawesome';

const style = (theme) => ({
  topHeader: {
    fontSize: theme.topHeader.fontSize,
    background: theme.palette.primary.main,
    color: theme.topHeader.color,
  },
  topHeaderLink: {
    color: theme.topHeader.color,
    '&:hover': {
      color: theme.topHeader.hoverColor,
      textDecoration: 'none',
    },
  },
});

const TopHeader = ({ classes, theme, layout }) => {
  const topHeaderLink = classes.topHeaderLink;
  const classConstant = theme.classConstant;
  let leftLine = null;
  let socialMedia = null;
  const urlSuffix = theme.webId == 24 ? '&team_id=613' : '';
  const colLeft = theme.webId == 24 ? 'col-md-6' : 'col-md-8';
  const colRight = theme.webId == 24 ? 'col-md-6' : 'col-md-4';

  if (layout.socialmedia.data.length) {
    leftLine = <li className="list-inline-item">|</li>;
    socialMedia = layout.socialmedia.data.map((socialmedia) => (
      <li className="list-inline-item" key={socialmedia.icon}>
        <a
          href={socialmedia.link}
          target="_blank"
          title={socialmedia.title}
          className={topHeaderLink}
          rel="noopener noreferrer"
        >
          <span className={`fa ${socialmedia.icon}`}></span>
        </a>
      </li>
    ));
  }

  return (
    <div className={classes.topHeader}>
      <div className={classConstant.CONTAINER_CLASS}>
        <div className="row text-center text-md-left">
          <div className={colLeft}>
            <ul className="list-inline mb-0 py-2">
              {layout.profile.data.phone ? (
                <li className="list-inline-item">
                  <a
                    href={`tel:${layout.profile.data.phone}`}
                    className={topHeaderLink}
                  >
                    <FA name="phone" className="mr-1" />{' '}
                    {layout.profile.data.phone}
                  </a>
                </li>
              ) : (
                ''
              )}
              {layout.profile.data.email ? (
                <li className="list-inline-item">
                  <a
                    href={`mailto:${layout.profile.data.email}`}
                    className={topHeaderLink}
                  >
                    <FA name="envelope" className="mr-1" />
                    {layout.profile.data.email}
                  </a>
                </li>
              ) : (
                ''
              )}
              {layout.profile.data.address ? (
                <li className="list-inline-item">
                  <a
                    href={`https://www.google.com/maps/search/${layout.profile.data.address}`}
                    className={topHeaderLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FA name="map-marker" className="mr-1" />
                    {layout.profile.data.address}
                  </a>
                </li>
              ) : (
                ''
              )}
            </ul>
          </div>
          <div className={colRight}>
            <ul className="list-inline mb-0 pt-md-2 pb-2 text-md-right">
              {/* <li className="list-inline-item">
                <Link to="/about-us" className={topHeaderLink}>
                  About Us
                </Link>
              </li> */}
              {/* <li className="list-inline-item">
                <Link to="/download" className={topHeaderLink}>
                  Download
                </Link>
              </li> */}
              {theme.webId == 24 ? (
                <>
                  {/* <li className="list-inline-item">
                    <a
                      href="https://mis.bnks.edu.np/e/bnksportal/entrance/login"
                      style={{
                        border: '1px solid #fff',
                        padding: '4px 5px',
                        borderRadius: '4px',
                      }}
                      target="_blank"
                      className={topHeaderLink}
                    >
                      Online Entrance Login
                    </a>
                  </li> */}
                  <li className="list-inline-item">
                    <a
                      href="https://mis.bnks.edu.np/s/apply/bnks"
                      style={{
                        color: '#6bff20',
                        border: '1px solid #6bff20',
                        padding: '4px 5px',
                        borderRadius: '4px',
                      }}
                      target="_blank"
                      className={topHeaderLink}
                    >
                      <i className="fa fa-pencil"></i> Apply Online
                    </a>
                  </li>
                </>
              ) : (
                <li className="list-inline-item">
                  <Link to="/contact" className={topHeaderLink}>
                    Contact
                  </Link>
                </li>
              )}

              {theme.webId != 22 && theme.webId != 21 && theme.webId != 16 ? (
                <UncontrolledDropdown className="list-inline-item">
                  <DropdownToggle nav caret className={`${topHeaderLink} p-0`}>
                    <FA name="lock" className="mr-1" />
                    Login
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      target="_blank"
                      href={`https://edigitalnepal.com/sms/login?role=admin${urlSuffix}`}
                    >
                      Admin Login
                    </DropdownItem>
                    <DropdownItem
                      target="_blank"
                      href={`https://edigitalnepal.com/sms/login?role=student${urlSuffix}`}
                    >
                      Student Login
                    </DropdownItem>
                    <DropdownItem
                      target="_blank"
                      href={`https://edigitalnepal.com/sms/login?role=teacher${urlSuffix}`}
                    >
                      Teacher Login
                    </DropdownItem>
                    <DropdownItem
                      target="_blank"
                      href={`https://edigitalnepal.com/sms/login?role=parent${urlSuffix}`}
                    >
                      Parent Login
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : null}
              {leftLine}
              {socialMedia}
              <li className="list-inline-item">
                <a
                  href="https://bnks.divyat.com"
                  className={topHeaderLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Accment
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectSheet(style)(TopHeader);
