import React from "react";
import MyCard from "./MyCard";

//react-jss
import injectSheet from "react-jss";

const styles = {
  row: props => ({
    justifyContent: props.theme.contentJustify
  })
};

const MyCardList = ({
  items,
  classes,
  resource_path,
  columns,
  theme,
  onCardClick
}) => {
  return (
    <div className={`row ${classes.row}`}>
      {items.map((item, i) => (
        <div className={`col-md-${12 / columns} mb-4`} key={i}>
          <MyCard
            item={item}
            resource_path={resource_path}
            theme={theme}
            itemIndex={i}
            onCardItemClick={onCardClick}
          />
        </div>
      ))}
    </div>
  );
};

export default injectSheet(styles)(MyCardList);
