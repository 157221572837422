import React, { Component } from "react";

//alice-carousel import
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

// react-jss import
import injectSheet from "react-jss";

// font awesome import
import FA from "react-fontawesome";

//asset import
import "./MyCarousel.css";

const style = theme => ({
  slideButton: {
    width: props => (props.slideButton.width ? props.slideButton.width : 40),
    height: props => (props.slideButton.height ? props.slideButton.height : 50),
    fontSize: props =>
      props.slideButton.fontSize ? props.slideButton.fontSize : 20,
    color: props =>
      props.slideButton.color ? props.slideButton.color : "#fff",
    backgroundColor: props =>
      props.slideButton.backgroundColor
        ? props.slideButton.backgroundColor
        : theme.palette.primary.main,
    "&:hover": {
      color: props =>
        props.slideButton.hoverColor ? props.slideButton.hoverColor : "#fff",
      backgroundColor: props =>
        props.slideButton.hoverBackgroundColor
          ? props.slideButton.hoverBackgroundColor
          : theme.palette.primary.hover
    }
  },
  slideButtonLeft: {
    left: props => props.borderSpacing
  },
  slideButtonRight: {
    right: props => props.borderSpacing
  }
});

//example
/* <MyCarousel
  items={this.slideItems()}
  dots={true}
  dotsPosition="inside"
  arrows={true}
  responsive={this.sliderResponsive()}
  autoPlay={false} 
  arrowWidth={20}
  arrowHeight={30}
  borderSpacing={0}
/>; */

class MyCarousel extends Component {
  state = { currentIndex: 0 };

  render() {
    const { classes } = this.props;
    const singleItem = this.props.items.length <= 1;
    const autoPlay = singleItem ? false : (typeof this.props.autoPlay === "boolean" ? this.props.autoPlay : true);
    return (
      <div
        className={
          (this.props.dotsPosition ? this.props.dotsPosition + " " : "") +
          "carousel-outer"
        }
      >
        <AliceCarousel
          items={this.props.items}
          autoPlay={autoPlay}
          stopAutoPlayOnHover={(typeof this.props.stopAutoPlayOnHover === "boolean") ? this.props.stopAutoPlayOnHover : true}
          mouseDragEnabled={
            this.props.mouseDragEnabled ? this.props.mouseDragEnabled : false
          }
          autoPlayInterval={this.props.interval ? this.props.interval : 6000}
          responsive={this.props.responsive}
          dotsDisabled={singleItem || !this.props.dots}
          buttonsDisabled={true}
          duration={this.props.duration ? this.props.duration : 250}
          fadeOutAnimation={this.props.fadeOutAnimation ? this.props.fadeOutAnimation : false}
          // stagePadding={{ paddingLeft: 20, paddingRight: 20 }}
          ref={el => (this.Carousel = el)}
        />

        {this.props.arrows && !singleItem ? (
          <React.Fragment>
            <button
              onClick={() => this.Carousel.slidePrev()}
              onMouseOver={() => this.Carousel.setState({ isPlaying: false })}
              onMouseOut={() => this.Carousel.setState({ isPlaying: true })}
              className={`${classes.slideButton} ${classes.slideButtonLeft} btn carousel-control left-carousel-control`}
            >
              <FA
                name={`${
                  this.props.slideButton.icon
                    ? this.props.slideButton.icon
                    : "angle"
                  }-left`}
              />
            </button>
            <button
              onClick={() => this.Carousel.slideNext()}
              onMouseOver={() => this.Carousel.setState({ isPlaying: false })}
              onMouseOut={() => this.Carousel.setState({ isPlaying: true })}
              className={`${classes.slideButton} ${classes.slideButtonRight} btn carousel-control right-carousel-control`}
            >
              <FA
                name={`${
                  this.props.slideButton.icon
                    ? this.props.slideButton.icon
                    : "angle"
                  }-right`}
              />
            </button>
          </React.Fragment>
        ) : (
            ""
          )}
      </div>
    );
  }
}

export default injectSheet(style)(MyCarousel);
