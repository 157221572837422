import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { reducer as reduxFormReducer } from "redux-form";

import {
  layoutReducer,
  breadCrumbReducer,
  activeBreadCrumbReducer,
  homePageReducer,
  pageReducer,
  errorReducer
} from "./frontReducer";

export default history =>
  combineReducers({
    router: connectRouter(history),
    layout: layoutReducer,
    breadCrumb: breadCrumbReducer,
    activeBreadCrumb: activeBreadCrumbReducer,
    home: homePageReducer,
    page: pageReducer,
    form: reduxFormReducer,
    error: errorReducer
  });
