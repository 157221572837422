export default {
  language: "en",
  font_urls: ["https://fonts.googleapis.com/css?family=Roboto&display=swap"],
  elements: [],
  palette: {
    primary: {
      main: "#000"
    },
    secondary: {
      main: "#ccc"
    }
  }
};
