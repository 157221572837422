import React, { useState } from "react";
import { Link } from "react-router-dom";

//reactstrap import
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

const MyUncontrolledDropdown = ({ navItem }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  //hide the dropdown on dropdown item click
  const [dropdownClass, toggleDropdownClass] = useState("");

  return (
    <Dropdown
      isOpen={dropdownOpen}
      toggle={() =>
        dropdownOpen ? setDropdownOpen(false) : setDropdownOpen(true)
      }
      nav
      inNavbar
      onMouseOver={() => toggleDropdownClass("")}
    >
      <DropdownToggle nav caret>
        {navItem.label}
      </DropdownToggle>
      <DropdownMenu className={dropdownClass}>
        {navItem.submenu.map((submenuItem, i) => {
          return (
            <DropdownItem
              tag={Link}
              to={`/${navItem.slug}/${submenuItem.slug}`}
              key={submenuItem.slug + i}
              onClick={() => toggleDropdownClass("d-none")}
            >
              {submenuItem.label}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

export default MyUncontrolledDropdown;
