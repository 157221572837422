import React from "react";
import { Link } from "react-router-dom";

import { Breadcrumb, BreadcrumbItem } from "reactstrap";

import injectStyleSheet from "react-jss";

const styles = theme => ({
  breadCrumbWrapper: {
    background: "#eee"
  },
  breadCrumb: {
    padding: "8px 0",
    fontSize: 13,
    "& a": {
      color: theme.palette.primary.main
    }
  }
});

const MyBreadCrumb = ({ classes, theme, breadCrumb, activeBreadCrumb }) => {
  const classConstant = theme.classConstant;

  let breadCrumbList =
    breadCrumb.submenu && activeBreadCrumb.label ? (
      <React.Fragment>
        <BreadcrumbItem>
          <Link to={"/" + breadCrumb.slug}>{breadCrumb.label}</Link>
        </BreadcrumbItem>
        <BreadcrumbItem active>{activeBreadCrumb.label}</BreadcrumbItem>
      </React.Fragment>
    ) : (
      <BreadcrumbItem active>{breadCrumb.label}</BreadcrumbItem>
    );

  breadCrumbList = (
    <div className={classes.breadCrumbWrapper}>
      <div className={classConstant.CONTAINER_CLASS}>
        <Breadcrumb className={classes.breadCrumb}>
          <BreadcrumbItem>
            <Link to="/">{theme.language == "np" ? "गृहपृष्ठ" : "Home"}</Link>
          </BreadcrumbItem>
          {breadCrumbList}
        </Breadcrumb>
      </div>
    </div>
  );

  return <React.Fragment>{breadCrumbList}</React.Fragment>;
};

export default injectStyleSheet(styles)(MyBreadCrumb);
