import React from "react";
import { Link } from "react-router-dom";

// reactstrap import
import { Card, CardImg, CardText, CardBody, CardTitle } from "reactstrap";

//react-jss
import injectSheet from "react-jss";

const styles = {
  card: props => props.theme.main,
  cardImgContainer: props => props.theme.imageContainer,
  cardImg: props => props.theme.image,
  cardTitle: props => props.theme.title,
  cardBody: props => props.theme.cardBody,
  cardText: props => props.theme.cardText
};

const MyCard = props => {
  const { classes, item, resource_path, itemIndex, onCardItemClick } = props;
  let image = null;
  let cardTitle = null;

  if (item.image) {
    const cardImg = (
      <div className={classes.cardImgContainer}>
        <CardImg
          className={classes.cardImg}
          src={resource_path + item.image}
          alt={item.title}
        />
      </div>
    );
    if (item.link) {
      image = <Link to={item.link}>{cardImg}</Link>;
    } else {
      image = cardImg;
    }
  }

  if (item.title) {
    cardTitle = (
      <CardTitle className={classes.cardTitle}>{item.title}</CardTitle>
    );
    if (item.link) {
      cardTitle = (
        <Link to={item.link}>
          <CardTitle className={classes.cardTitle}>{cardTitle}</CardTitle>
        </Link>
      );
    }
  }

  return (
    <Card
      className={classes.card}
      onClick={onCardItemClick ? () => onCardItemClick(itemIndex) : null}
    >
      {image}
      <CardBody className={classes.cardBody}>
        {cardTitle}
        {item.description ? (
          <CardText tag="div" className={classes.cardText}>
            <div dangerouslySetInnerHTML={{ __html: item.description }} />
          </CardText>
        ) : null}

        {/* {item.link ? (
          <Button color="primary" tag={Link} to={item.link}>
            View More
          </Button>
        ) : (
          ""
        )} */}
      </CardBody>
    </Card>
  );
};

export default injectSheet(styles)(MyCard);
