import * as types from "../actions/actionTypes";
import initialState from "./initalState";

export const layoutReducer = (state = initialState.layout, action) => {
  if (action.type === types.FETCH_LAYOUT_DATA) {
    return Object.assign({}, state, action.payload);
  } else return state;
};

export const breadCrumbReducer = (state = initialState.breadCrumb, action) => {
  if (action.type === types.FETCH_BREADCRUMB_DATA) {
    return Object.assign({}, state, action.payload);
  } else return state;
};

export const activeBreadCrumbReducer = (
  state = initialState.activeBreadCrumb,
  action
) => {
  if (action.type === types.ACTIVE_BREADCRUMB) {
    return action.payload ? Object.assign({}, state, action.payload) : [];
  } else return state;
};

export const homePageReducer = (state = initialState.homePage, action) => {
  if (action.type === types.FETCH_HOMEPAGE_DATA)
    return Object.assign({}, state, action.payload);
  else return state;
};

export const pageReducer = (state = initialState.page, action) => {
  if (action.type === types.FETCH_PAGE_DATA)
    return Object.assign({}, state, action.payload);
  else return state;
};

export const errorReducer = (state = initialState.error, action) => {
  if (action.type === types.SET_ERROR)
    return Object.assign({}, state, action.payload);
  else return state;
};
