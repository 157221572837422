import React from "react";
import { Toast, ToastBody } from "reactstrap";

// react-jss import
import injectStyleSheet from "react-jss";

const styles = {
  toast: {
    position: "fixed",
    bottom: 0,
    right: 15,
    fontSize: 13,
    background: "#000",
    color: "#fff",
    borderLeft: "4px solid #c00",
    zIndex: 1000
  },
  toastBody: {
    padding: "9px 12px"
  }
};

const Error = ({ children, classes }) => {
  return (
    <Toast className={classes.toast}>
      <ToastBody className={classes.toastBody}>{children}</ToastBody>
    </Toast>
  );
};

export default injectStyleSheet(styles)(Error);
