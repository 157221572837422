import React, { useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import Loader from "../../images/loaders/oval.svg";

const PopUp = (props) => {
    const {
        buttonLabel,
        className,
        popup,
    } = props;

    const [modal, setModal] = useState(true);

    const toggle = () => {
        setModal(!modal);
        // localStorage.setItem('isPopUpShown', true);
    }

    // const isPopUpShown = localStorage.getItem('isPopUpShown');    

    const resourcePath = popup.resource_path;
    const popupItems = popup.data;

    // const loader = (
    //     <div className="text-center">
    //         <object type="image/svg+xml" data={Loader} class="logo" width="70">
    //             Loading...
    // </object>
    //     </div>
    // );

    const closeButton = <button className="close" style={{ position: 'absolute', top: '4px', right: '4px',  width: '35px', fontSize: '35px', zIndex:10 }} onClick={toggle}>&times;</button>;

    let popupContent = 0 ? null : <div>
                <Modal isOpen={modal} toggle={toggle} className={`${className} modal-lg`} style={{maxWidth: '600px'}}>
                    {closeButton}
                    <ModalBody className="pl-2 pr-2 pt-2 pb-0">
                    {popupItems.map((item) => (
                            <div key={item.id} className="pb-2">
                                {item.page.image ? <img className="img-fluid" src={resourcePath + item.page.image} alt={item.page.title} /> : null}
                                {item.page.description ? <div
                                className="text-justify p-2 mt-2"
                                dangerouslySetInnerHTML={{
                                __html: item.page.description
                                }}
                            /> : null}            
                            </div>
                        ))}
                    </ModalBody>
                </Modal>
            </div>

    return (
    <React.Fragment>{popupContent}</React.Fragment>
    );
};

export default PopUp;