import React, { useState, useCallback } from "react";

//react images fro gallery
import Carousel, { Modal, ModalGateway } from "react-images";

// component styling
import injectSheet from "react-jss";

//component import
import PageTitle from "./common/PageTitle";
import MyCardList from "../card/MyCardList";

// //asset import
import Loader from "../../images/loaders/oval.svg";

const styles = theme => ({});

const COLUMN_TEMPLATE = ({ theme, page, activeBreadCrumb }) => {
  let data = page.data;

  let pageContent = (
    <div className="text-center">
      <object type="image/svg+xml" data={Loader} width="70">
        Loading...
      </object>
    </div>
  );

  const [currentImageListIndex, setCurrentImageListIndex] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback(index => {
    setCurrentImageListIndex(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImageListIndex(0);
    setViewerIsOpen(false);
  };

  if (
    data.length &&
    data[0].images &&
    data[0].images.length &&
    data[0].images[0].src
  ) {
    data.map((d, i) => {
      return (data[i].image = d.images[0].src);
    });

    pageContent = (
      <MyCardList
        items={data}
        resource_path={page.resource_path}
        columns={3}
        theme={theme.photoGallery.card}
        onCardClick={openLightbox}
      />
    );
  }

  // if (Array.isArray(data)) {
  //   if (!data.length) {
  //     page.data.push({ images: [] });
  //   } else {
  //     if (data[0].images[0]) {
  //       data.map((d, i) => {
  //         return (data[i].image = d.images[0].src);
  //       });
  //     }

  //   }
  // }

  return (
    <React.Fragment>
      <PageTitle title={activeBreadCrumb.label} />
      {pageContent}
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              views={data[currentImageListIndex].images.map(x => {
                const y = { src: page.resource_path + x.src };
                return {
                  ...y,
                  srcset: y.srcSet,
                  caption: data[currentImageListIndex].title
                };
              })}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </React.Fragment>
  );
};

export default injectSheet(styles)(COLUMN_TEMPLATE);
