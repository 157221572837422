import React from "react";
import { Link } from "react-router-dom";

// react-jss
import injectSheet from "react-jss";

// component import
import MyCarousel from "../carousel/MyCarousel";

const styles = theme => ({
  button: {
    background: theme.palette.secondary.main,
    fontSize: 10
  },
  label: {
    fontWeight: "bold"
  },
  captionContainer: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    color: "#fff",
    justifyContent: theme.slider.captionContainer.itemAlign,
    textAlign: theme.slider.captionContainer.textAlign
  },
  captionContent: {
    maxWidth: 500
  },
  description: {
    fontSize: 20,
  },
  captionHeading: theme.slider.caption.heading,
  captionButton: {
    color: theme.slider.caption.button.color,
    backgroundColor: theme.slider.caption.button.backgroundColor,
    fontSize: theme.slider.caption.button.fontSize,
    paddingTop: theme.slider.caption.button.paddingY,
    paddingBottom: theme.slider.caption.button.paddingY,
    paddingLeft: theme.slider.caption.button.paddingX,
    paddingRight: theme.slider.caption.button.paddingX,
    "&:hover": {
      color: theme.slider.caption.button.hoverColor,
      backgroundColor: theme.slider.caption.button.hoverBackgroundColor
    }
  }
});

const slideButton = {
  // width: 30,
  // height: 20,
  // background: "#c00"
  // color: "#f90",
  // hoverColor: "#a00",
  // hoverBackgroundColor: "#0e0",
  icon: "chevron"
};

const HomeCarousel = ({ classes, theme, slides, layout }) => {
  const classConstant = theme.classConstant;

  const slideItems = () => {
    return slides.data.map(slide => (
      <div className="item" key={slide.image}>
        <img
          className="w-100"
          src={slides.resource_path + slide.image}
          alt={slide.title}
        />
        <div className="slider-content with-overlay">
          <div
            className={`${classConstant.CONTAINER_CLASS} ${classes.captionContainer}`}
          >
            <div className={classes.captionContent}>
              {slide.title ? (
                <h1
                  className={classes.captionHeading}
                  data-animations="fadeInUp"
                  data-animation-delay="0.2s"
                >
                  {slide.title}
                </h1>
              ) : (
                  ""
                )}
              {slide.description ? (
                <div
                  className={`line-height-normal animate-plus mb-4 ${classes.description}`}
                  data-animations="fadeInUp"
                  data-animation-delay="0.3s"
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: slide.description }}
                  />
                </div>
              ) : (
                  ""
                )}
              {slide.link ? (
                <Link
                  to={slide.link}
                  className={`btn ${classes.captionButton}`}
                >
                  View More
                </Link>
              ) : (
                  ""
                )}
            </div>
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="main-slider">
      <MyCarousel
        items={slideItems()}
        dots={true}
        dotsPosition="inside"
        arrows={true}
        autoPlay={true}
        slideButton={slideButton}
        stopAutoPlayOnHover={layout.profile.data.id != 24}
        duration = {layout.profile.data.id == 24 ? 700 : 250}
        fadeOutAnimation={layout.profile.data.id == 24}
      />
    </div>
  );
};

export default injectSheet(styles)(HomeCarousel);
