//theme import
import generateTheme from "../theme/Theme";
import defaultTheme from "./defaultTheme";

export default {
  layout: {
    //meta: { data: [] },
    event: {
      data: []
    },
    menu: [],
    profile: {
      data: {
        logo: "",
        logo_theme: "1",
        name: "",
        banner_image: null
      },
      logo_theme: 1
    },
    socialmedia: {
      data: []
    },
    isLoaded: false,
    list_content: null,
    theme: generateTheme(defaultTheme)
  },
  breadCrumb: {},
  activeBreadCrumb: {},
  homePage: {
    slider: {
      data: []
    },
    popup: {
      data: []
    },
    intro: {
      data: null
    },
    program: {
      data: []
    },
    notice: {
      data: []
    },
    event: {
      data: []
    },
    testimonial: {
      data: []
    },
    partner: {
      data: []
    }
  },
  page: {
    data: []
  },
  galleryImages: [],
  contactForm: {
    name: "",
    email: "",
    subject: "",
    message: ""
  },
  error: null
};
