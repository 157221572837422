import React, { Component } from "react";
import { connect } from "react-redux";

//action import
import {
  getBreadCrumbList,
  sendContactMessage
} from "../../actions/frontAction";

// react-jss
import injectSheet from "react-jss";

// //asset import
import Loader from "../../images/loaders/oval.svg";

// component import
import Layout from "../layout/Layout";
import MyBreadCrumb from "../common/BreadCrumb";
import ContactForm from "./ContactForm";
import PageHeader from "../common/PageHeader";

const styles = theme => ({
  pageTitle: theme.page.pageTitle,
  aside: theme.page.aside,
  map: {
    "& iframe": { width: "100%" }
  }
});

const loader = (
  <div className="text-center">
    <object type="image/svg+xml" data={Loader} width="70">
      Loading...
    </object>
    <div className="text-center mt-2 text-muted">Sending Message...</div>
  </div>
);

class Contact extends Component {
  state = {
    sending: false,
    messageSent: false,
    response: { message: "", success: true }
  };

  handleFormSubmit = formData => {
    this.setState({ sending: true });

    const _this = this;

    //send message through api
    this.props
      .sendContactMessage(formData)
      .then(response => {
        let resp = { message: response.message };
        if (response.status) {
          resp.success = true;
          this.setState({ name: "", email: "", subject: "", message: "" });
        } else {
          resp.success = false;
        }
        _this.setState({ response: resp });
        _this.setState({ sending: false, messageSent: true });
      })
      .catch(error => {
        alert("Something went wrong!");
        _this.setState({ sending: false, messageSent: false });
      });
  };

  render() {
    const { classes, theme, map, breadCrumb, activeBreadCrumb, profile } = this.props;
    const classConstant = theme.classConstant;
    // console.log(breadCrumb, activeBreadCrumb);

    const messageSentOutput = (
      <h6
        className={`text-center text-${
          this.state.response.success ? "success" : "danger"
          }`}
      >
        {this.state.response.message}
      </h6>
    );

    const form = <ContactForm onSubmit={this.handleFormSubmit} />;

    return (
      <Layout>
        {profile.data.banner_image ? (
          <PageHeader
            image={profile.resource_path + profile.data.banner_image}
          />
        ) : null}
        <MyBreadCrumb
          breadCrumb={breadCrumb}
          activeBreadCrumb={activeBreadCrumb}
        />
        <section className="content">
          <div className={classConstant.CONTAINER_CLASS}>
            <h2 className={classes.pageTitle}>Contact</h2>

            {this.state.sending
              ? loader
              : this.state.messageSent
                ? messageSentOutput
                : form}

            {map ? (
              <React.Fragment>
                <hr className="my-5" />
                <div
                  className={classes.map}
                  dangerouslySetInnerHTML={{
                    __html: map
                  }}
                />
              </React.Fragment>
            ) : null}
          </div>
        </section>
      </Layout>
    );
  }

  componentWillMount() {
    this.props.getBreadCrumbList();
  }
}

function mapStateToProps(state) {
  return {
    map: state.layout.profile.data.map,
    breadCrumb: state.breadCrumb,
    activeBreadCrumb: state.activeBreadCrumb,
    profile: state.layout.profile
  };
}

export default injectSheet(styles)(
  connect(
    mapStateToProps,
    { getBreadCrumbList, sendContactMessage }
  )(Contact)
);
