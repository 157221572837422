import React, { Component } from "react";
import { connect } from "react-redux";

import { Button } from "reactstrap";

// component import
import Header from "./header/Header";
import Footer from "./footer/Footer";
import Error from "../error/Error";

import FA from "react-fontawesome";

import injectSheet from "react-jss";

// for scrolling page on button click
import { animateScroll as scroll } from "react-scroll";

// //asset import
import Loader from "../../images/loaders/oval.svg";

const styles = theme => ({
  loaderDiv: {
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    background: "#eee"
  },
  content: {
    minHeight: 600
  },
  scrollUp: {
    bottom: 20,
    cursor: "pointer",
    display: "none",
    position: "fixed",
    right: 20,
    zIndex: "999",
    background: theme.palette.primary.hover,
    borderColor: theme.palette.primary.hover
  },
  visibleScrollUp: {
    display: "block"
  }
});

class Layout extends Component {
  state = { scrollUpClass: "" };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = event => {
    const { pageYOffset } = window;
    const { classes } = this.props;

    if (pageYOffset > 400) {
      this.setState({ scrollUpClass: classes.visibleScrollUp });
    } else {
      this.setState({ scrollUpClass: "" });
    }
  };

  scrollToTop() {
    scroll.scrollToTop();
  }

  render() {
    const { layout, classes, children } = this.props;

    let pageContent = (
      <div className={`${classes.loaderDiv} text-center`}>
        <object type="image/svg+xml" data={Loader} width="70">
          Loading...
        </object>
      </div>
    );

    if (layout.isLoaded) {
      pageContent = (
        <React.Fragment>
          <Header layout={layout} />
          <div className={classes.content}>{children}</div>
          <Footer layout={layout} />
          {this.props.error ? <Error>{this.props.error}</Error> : null}
          <Button
            id="scrollUp"
            color="primary"
            className={`${classes.scrollUp} ${this.state.scrollUpClass}`}
            onClick={this.scrollToTop}
          >
            <FA name="arrow-up" />
          </Button>
        </React.Fragment>
      );
    }

    return <React.Fragment>{pageContent}</React.Fragment>;
  }
}

function mapStateToProps(state) {
  return {
    layout: state.layout
  };
}

export default injectSheet(styles)(connect(mapStateToProps, null)(Layout));
