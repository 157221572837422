import React from "react";

// react-jss
import injectSheet from "react-jss";

// custom styles
const styles = {
  pageHeader: {
    height: props => (props.height ? props.height : "230px"),
    backgroundImage: props => (props.image ? `url(${props.image})` : "")
    // "&:after": {
    //   background: "rgba(0,0,0,0.2)"
    // }
  }
};

const PageHeader = props => {
  const { classes } = props;
  return <div className={classes.pageHeader + " with-bg with-overlay page-header"} />;
};

export default injectSheet(styles)(PageHeader);
