import { createTheme } from "./createTheme";

export default function (theme) {
  //generating spreadable element data
  let typography = {};
  theme.elements.map(el => {
    typography[el.elements.name] = {
      fontSize: el.size,
      fontWeight: el.weight,
      color: el.color,
      fontFamily: `'${el.font_family.name}', sans-serif`
    };
  });

  return createTheme({
    webId: theme.webId,
    language: theme.language ? theme.language : "en",
    classConstant: {
      CONTAINER_CLASS: "container"
    },
    typography: {
      font_urls: theme.font_urls.length
        ? theme.font_urls
        : ["https://fonts.googleapis.com/css?family=Roboto&display=swap"],
      fontFamily: [
        typography.body && typography.body.fontFamily
          ? typography.body.fontFamily
          : "Roboto, sans-serief"
      ],
      fontSize:
        typography.body && typography.body.fontSize
          ? typography.body.fontSize
          : 14,
      fontWeight:
        typography.body && typography.body.fontWeight
          ? typography.body.fontWeight
          : 500,
      lineHeight:
        typography.body && typography.body.lineHeight
          ? typography.body.lineHeight
          : 24,
      color:
        typography.body && typography.body.color
          ? typography.body.color
          : "#222",

      h1: {
        fontSize:
          typography.h1 && typography.h1.fontSize ? typography.h1.fontSize : 34,
        fontWeight:
          typography.h1 && typography.h1.fontWeight
            ? typography.h1.fontWeight
            : 700
      },
      h2: {
        fontSize:
          typography.h2 && typography.h2.fontSize ? typography.h2.fontSize : 30,
        fontWeight:
          typography.h2 && typography.h2.fontWeight
            ? typography.h2.fontWeight
            : 600
      },
      h3: {
        fontSize:
          typography.h3 && typography.h3.fontSize ? typography.h1.fontSize : 28,
        fontWeight:
          typography.h3 && typography.h3.fontWeight
            ? typography.h3.fontWeight
            : 600
      },
      h4: {
        fontSize:
          typography.h4 && typography.h4.fontSize ? typography.h4.fontSize : 25,
        fontWeight:
          typography.h4 && typography.h4.fontWeight
            ? typography.h4.fontWeight
            : 600
      },
      h5: {
        fontSize: 30,
        fontWeight: 600
      },
      h6: {
        fontSize: 30,
        fontWeight: 600
      }
    },
    palette: {
      primary: {
        main: theme.palette.primary.main,
        hover: theme.palette.secondary.main
      },
      secondary: {
        main: theme.palette.secondary.main,
        hover: theme.palette.secondary.main
      },
      grey: {
        main: "#f0f0f0"
      }
    },
    utils: {
      borderRadius: 4,
      boxShadow: "0 3px 10px rgba(0,0,0,0.2)",
      overlayBackgroundColor: "rgba(0,0,0,0.2)"
    },
    button: {}
  });
}
