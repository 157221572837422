import React, { Component } from "react";
import { Route } from "react-router-dom";
import NProgress from "nprogress";

import "../../../node_modules/nprogress/nprogress.css";

NProgress.configure({ easing: "ease", speed: 500 });

function RouteWithSubRoutes(route) {
  //   let subRoutes = null;
  return (
    <React.Fragment>
      <Route
        exact={route.exact}
        path={route.path}
        component={route.component}
        templateId={route.templateId}
      />
      {/* {route.children.length
        ? route.children.map((subroute, i) => (
            <Route
              key={i}
              path={subroute.path}
              component={subroute.component}
            />
          ))
        : null} */}
    </React.Fragment>
  );
}

class ApiRoute extends Component {
  componentWillMount() {
    NProgress.start();
  }

  componentDidMount() {
    NProgress.done();
  }

  render() {
    return <RouteWithSubRoutes {...this.props} />;
  }
}

export default ApiRoute;
