import React from "react";
import { Field, reduxForm } from "redux-form";

// reactstrap import
import { Col, Row } from "reactstrap";

const required = value => (value ? undefined : "Required");
const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

const renderField = ({
  input,
  label,
  type,
  fieldType,
  rows,
  meta: { touched, error, warning }
}) => {
  return (
    <div className="form-group">
      <label>{label}</label>
      {fieldType && fieldType === "textarea" ? (
        <textarea
          {...input}
          placeholder={label}
          type={type}
          className="form-control"
          rows={rows}
        />
      ) : (
        <input
          {...input}
          placeholder={label}
          type={type}
          className="form-control"
        />
      )}
      {touched &&
        ((error && <span className="text-danger">{error}</span>) ||
          (warning && <span className="text-warning">{warning}</span>))}
    </div>
  );
};

const ContactForm = props => {
  const { handleSubmit, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        <Col md={6}>
          <Field
            type="text"
            name="name"
            placeholder="Enter your Name"
            component={renderField}
            label="Name"
            validate={required}
          />
        </Col>
        <Col md={6}>
          <Field
            type="email"
            name="email"
            placeholder="Enter your Email"
            component={renderField}
            label="Email"
            validate={[required, email]}
          />
        </Col>
      </Row>
      <Field
        type="text"
        name="subject"
        placeholder="Enter your Subject"
        component={renderField}
        label="Subject"
        validate={required}
      />
      <Field
        type="text"
        name="message"
        placeholder="Enter your Message"
        component={renderField}
        fieldType="textarea"
        rows="7"
        label="Message"
        validate={required}
      />
      <div>
        <button type="submit" className="btn btn-primary" disabled={submitting}>
          Send Message
        </button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "fieldLevelValidation" // a unique identifier for this form
})(ContactForm);
