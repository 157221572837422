import React from "react";

// component import
import LIST_TEMPLATE from "./LIST_TEMPLATE";
import DETAIL_LIST_TEMPLATE from "./DETAIL_LIST_TEMPLATE";
import DETAIL_TEMPLATE from "./DETAIL_TEMPLATE";
import PHOTO_GALLERY_TEMPLATE from "./PHOTO_GALLERY_TEMPLATE";
import VIDEO_GALLERY_TEMPLATE from "./VIDEO_GALLERY_TEMPLATE";
import STUDENT_RECORD_TEMPLATE from "./STUDENT_RECORD_TEMPLATE";

const components = {
  LIST_TEMPLATE,
  DETAIL_LIST_TEMPLATE,
  DETAIL_TEMPLATE,
  PHOTO_GALLERY_TEMPLATE,
  VIDEO_GALLERY_TEMPLATE,
  STUDENT_RECORD_TEMPLATE
};

function MyTemplate(props) {
  const TemplateData = components[props.name];
  return <TemplateData {...props} />;
}

const Template = props => {
  return <MyTemplate {...props} />;
};

export default Template;
