import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";

//action import
import { getLayoutData } from "./actions/frontAction";

// for enabling theme object to be passed as HOC
import { ThemeProvider } from "react-jss";

// component import
import ApiRoute from "./components/route/ApiRoute";
import NoMatch from "./components/error/NoMatch";
import Blank from "./components/error/Blank";

// list of routes
import routes from "./components/route/routes";

class App extends Component {
  componentWillMount() {
    this.props.getLayoutData();
  }

  render() {
    const { theme, profile } = this.props.layout;

    //update the favicon and app theme color
    const link = document.querySelector('link[rel="shortcut icon"]');
    const appTheme = document.querySelector('meta[name="theme-color"]');
    link.href = `${profile.resource_path}favicon/${profile.data.favicon}`;
    appTheme.content = theme.palette.primary.main;

    //add necessary font links to the page
    const font_urls = theme.typography.font_urls;
    font_urls.map(font_url => {
      link.parentNode.insertBefore(
        generateFontLink(font_url),
        link.nextSibling
      );
    });

    return (
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <MetaTags>
            <title>{profile.data.name}</title>
            <meta name="description" content={profile.data.intro} />
            <meta property="og:title" content={profile.data.name} />
            <meta
              property="og:image"
              content={profile.resource_path + profile.data.logo}
            />
          </MetaTags>
          <Switch>
            <Route path="/502" component={Blank} />
            <Route path="/404" component={NoMatch} />
            <Route
              exact
              path="/gallery"
              render={() => <Redirect to="/gallery/photo" />}
            />
            {routes.map((route, index) => {
              return <ApiRoute key={index} {...route} />;
            })}
            <Route component={NoMatch} />
          </Switch>
        </React.Fragment>
      </ThemeProvider>
    );
  }
}

function mapStateToProps(state) {
  return {
    layout: state.layout
  };
}

function generateFontLink(font_url) {
  let link = document.createElement("link");
  link.rel = "stylesheet";
  link.rel_type = "font_url";
  link.href = font_url;
  return link;
}

export default connect(mapStateToProps, { getLayoutData })(App);
