import React from "react";
import { Link } from "react-router-dom";

// component styling
import injectSheet from "react-jss";

const styles = theme => ({
  pageTitle: theme.page.pageTitle
});

const PageTitle = ({ title, classes, link }) => {
  let pageTitle = <h2 className={classes.pageTitle}>{title}</h2>;
  if (link) pageTitle = <Link to={link}>{pageTitle}</Link>;
  return pageTitle;
};

export default injectSheet(styles)(PageTitle);
