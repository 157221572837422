import React from "react";
import { Link } from "react-router-dom";
import { getCurrentYear } from "../../../utils/Date";

// font awesome import
import FA from "react-fontawesome";

// react-jss
import injectSheet from "react-jss";

const styles = (theme) => ({
  footer: theme.footer.main,
  footerLogo: theme.footer.footerLogo,
  heading: theme.footer.heading,
  listItem: theme.footer.listItem,
  link: theme.footer.link,
  listHeading: theme.footer.listHeading,
});

const Footer = ({ classes, theme, layout }) => {
  const classConstant = theme.classConstant;
  let horizontalRow = null;
  let socialMedia = null;

  if (layout.socialmedia.data.length) {
    horizontalRow = <hr />;
    socialMedia = layout.socialmedia.data.map((socialmedia) => (
      <li className="list-inline-item mb-1" key={socialmedia.icon}>
        <a
          href={socialmedia.link}
          target="_blank"
          rel="noopener noreferrer"
          className={classes.link}
          title={socialmedia.title}
        >
          <span className={`fa ${socialmedia.icon}`}></span>
        </a>
      </li>
    ));
  }

  let list_content = null;
  if (layout.list_content.data && layout.list_content.data.length) {
    list_content = layout.list_content.data.map((list_item) => (
      <li className={classes.listItem} key={list_item.title}>
        <Link className={classes.link} to={`${layout.list_content.title}`}>
          <span className={classes.listHeading}>{list_item.title}</span>
        </Link>
      </li>
    ));
  }

  return (
    <footer className={classes.footer}>
      <div className={classConstant.CONTAINER_CLASS}>
        <div className="row">
          <div className={`col-lg-${list_content ? "4" : "5"}`}>
            {layout.profile.data.logo ? (
              <img
                src={layout.profile.resource_path + layout.profile.data.logo}
                className={`${classes.footerLogo} w-auto`}
                alt={`${layout.profile.data.name} logo`}
              />
            ) : null}
            <div className="mb-4">{layout.profile.data.intro}</div>
          </div>
          {list_content ? (
            <div className="col-lg-3">
              <h4 className={classes.heading}>
                Recent {layout.list_content.title}
              </h4>
              <ul className="list-styled-icon footer-navigation-list">
                {list_content}
              </ul>
            </div>
          ) : null}
          <div className={`col-6 col-lg-${list_content ? "2" : "3"}`}>
            <h4 className={classes.heading}>Useful Links</h4>
            <ul className="list-styled-icon footer-navigation-list">
              {layout.menu.map((navItem, i) => {
                if (i < 6)
                  return (
                    <li className={classes.listItem} key={navItem.slug}>
                      <Link className={classes.link} to={"/" + navItem.slug}>
                        {navItem.label}
                      </Link>
                    </li>
                  );
                else return null;
              })}
              <li className={classes.listItem}>
                <a
                  className={classes.link}
                  href="http://139.59.36.187:8080/index.html"
                  target="_blank"
                >
                  Library
                </a>
              </li>
            </ul>
          </div>
          <div className="col-6 col-lg-3">
            <h4 className={classes.heading}>Address</h4>
            {layout.profile.data.address ? (
              <address>{layout.profile.data.address}</address>
            ) : (
              ""
            )}
            <ul className="list-styled-icon">
              {layout.profile.data.email ? (
                <li className="email">
                  <a
                    href={`mailto:${layout.profile.data.email}`}
                    className={classes.link}
                  >
                    {layout.profile.data.email}
                  </a>
                </li>
              ) : (
                ""
              )}
              {layout.profile.data.phone ? (
                <li className="phone">
                  <a
                    href={`tel:${layout.profile.data.phone}`}
                    className={classes.link}
                  >
                    {layout.profile.data.phone}
                  </a>
                </li>
              ) : (
                ""
              )}
            </ul>
            {horizontalRow}
            <ul className="list-inline list-unstyled mb-0 social-icons">
              {socialMedia}
            </ul>
          </div>
        </div>
        <div className="copyright">
          <div className="row">
            <div className="col-lg-7 text-center text-lg-left">
              © {getCurrentYear()} {layout.profile.data.name}, All rights
              reserved.
            </div>
            <div className="col-lg-5 text-center text-lg-right">
              Powered By:{" "}
              <a
                className="powered-by"
                href="https://edigitalnepal.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Digital Nepal
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default injectSheet(styles)(Footer);
