import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "reactstrap";

//action import
import { getHomePageData } from "../../actions/frontAction";

// react-jss
import injectSheet from "react-jss";

// font awesome import
// import FA from "react-fontawesome";

// component import
import Layout from "../layout/Layout";
import MyCarousel from "../carousel/MyCarousel";
import HomeCarousel from "./HomeCarousel";
import ScrollingNotice from "./ScrollingNotice";
import MyCardList from "../card/MyCardList";
import MyCard from "../card/MyCard";
import PopUp from "../popup/PopUp";

//component styling
const styles = (theme) => ({
  button: {
    background: theme.palette.secondary.main,
    fontSize: 10,
  },
  label: {
    fontWeight: "bold",
  },
  introHeading: theme.section.introSection.heading,
  courseSection: {
    backgroundColor: theme.section.courseSection.backgroundColor,
  },
  courseHeading: theme.section.courseSection.heading,
  newsSection: theme.section.newsSection,

  testimonialSection: theme.section.testimonialSection.main,
  testimonialHeading: theme.section.testimonialSection.heading,

  sponsorSection: theme.section.sponsorSection.main,
});

// function chunk(array, size) {
//   const chunked_arr = [];
//   let index = 0;
//   while (index < array.length) {
//     chunked_arr.push(array.slice(index, size + index));
//     index += size;
//   }
//   return chunked_arr;
// }

class Home extends Component {
  slideButton = {
    //   width: 30,
    //   height: 20,
    //   background: "#c00",
    //   color: "#f90",
    //   hoverColor: "#a00",
    //   hoverBackgroundColor: "#0e0",
    //   icon: "chevron"
  };

  programs(programs) {
    const { theme, classes } = this.props;
    if (programs.data.length) {
      const programItems = programs.data.map((program) => {
        const duration = program.duration
          ? '<div class="mb-1"><i class="fa fa-clock-o mr-1"></i>' +
            program.duration +
            " years</div>"
          : "";
        return {
          title: program.title,
          image: program.image ? programs.resource_path + program.image : null,
          link: "/program/" + program.slug,
          description: duration + program.summary,
        };
      });
      return (
        <section className={`content ${classes.courseSection}`}>
          <div className={theme.classConstant.CONTAINER_CLASS}>
            <h2 className={`section-heading ${classes.courseHeading}`}>
              Our courses
            </h2>
            <div className="section-body">
              <MyCardList
                items={programItems}
                columns={theme.section.courseSection.columns}
                theme={theme.section.courseSection.card}
              />
            </div>
          </div>
        </section>
      );
    }
  }

  newsItems() {
    return this.props.home.notice.data.map((n) => {
      n.link = `/${this.props.home.notice.slug}/${n.slug}`;
      return (
        <MyCard
          item={n}
          resource_path={this.props.home.notice.resource_path}
          theme={this.props.theme.section.newsSection.card}
        />
      );
    });
  }

  eventItems() {
    return this.props.home.event.data.map((n) => {
      n.link = `/${this.props.home.event.slug}/${n.slug}`;
      return (
        <MyCard
          item={n}
          resource_path={this.props.home.event.resource_path}
          theme={this.props.theme.section.newsSection.card}
        />
      );
    });
  }

  scrollingNotice(notices) {
    if (notices.length) return <ScrollingNotice notices={notices} />;
  }

  aboutUs(about) {
    if (about.data) {
      const { theme, classes } = this.props;
      const introText = (
        <React.Fragment>
          {/* <h2 className={`section-heading ${classes.introHeading}`}>
            {about.data.title}
          </h2> */}
          <div className="section-body">
            <div
              dangerouslySetInnerHTML={{
                __html:
                  about.data.description.length < 700
                    ? about.data.description
                    : about.data.description.substring(0, 700) + "...",
              }}
            />
            <Button
              color="primary"
              tag={Link}
              to={`/about-us/${about.data.slug}`}
            >
              Read More
            </Button>
          </div>
        </React.Fragment>
      );

      return (
        <section className="content">
          <div className={theme.classConstant.CONTAINER_CLASS}>
            <div className={`row text-${theme.section.introSection.textAlign}`}>
              {about.data.image && theme.section.introSection.media ? (
                <React.Fragment>
                  <div
                    className={`col-lg-6 mb-3 ${
                      theme.section.introSection.mediaPosition === "right"
                        ? "order-lg-1"
                        : ""
                    }`}
                  >
                    {about.data.image ? (
                      <img
                        className="w-100"
                        src={about.resource_path + about.data.image}
                        alt={about.data.title}
                      />
                    ) : null}
                  </div>
                  <div className="col-lg-6">{introText}</div>
                </React.Fragment>
              ) : (
                <div className="col-lg-12">{introText}</div>
              )}
            </div>
          </div>
        </section>
      );
    }
  }

  noticeSection(notice) {
    if (notice.data.length) {
      const { classes } = this.props;
      return (
        <div className="col-md-6 mb-3">
          <h2 className={`section-heading ${classes.newsHeading}`}>
            Latest News
          </h2>
          <MyCarousel
            items={this.newsItems()}
            dots={true}
            autoPlay={true}
            slideButton={this.slideButton}
            interval={4100}
          />
        </div>
      );
    }
  }

  eventSection(events, layout) {
    if (events.data.length) {
      const { classes } = this.props;
      return (
        <div className="col-md-6 mb-3">
          <h2 className={`section-heading ${classes.newsHeading}`}>
            {layout.profile.data.id == 24 ? "Our" : "Upcomming"} Events
          </h2>
          <div className="section-body">
            <MyCarousel
              items={this.eventItems()}
              dots={true}
              autoPlay={true}
              slideButton={this.slideButton}
              interval={4500}
            />
          </div>
        </div>
      );
    }
  }

  testimonialItems(testimonials, theme, resourcePath, columns) {
    return testimonials.map((testimonial) => {
      return (
        <MyCard
          item={{
            title: testimonial.name,
            image: testimonial.image,
            description: testimonial.description,
          }}
          resource_path={resourcePath}
          columns={columns}
          theme={theme}
        />
      );
    });
  }

  testimoniaResponsive = {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1140: {
      items: 2,
    },
  };

  testimonialSection(testimonial) {
    if (testimonial.data.length) {
      const { theme, classes } = this.props;
      return (
        <section className={`content ${classes.testimonialSection}`}>
          <div className={theme.classConstant.CONTAINER_CLASS}>
            <h2 className={`section-heading ${classes.testimonialHeading}`}>
              Student's testimonials
            </h2>
            <MyCarousel
              items={this.testimonialItems(
                testimonial.data,
                theme.section.testimonialSection.card,
                testimonial.resource_path
              )}
              dots={true}
              autoPlay={true}
              slideButton={this.slideButton}
              responsive={this.testimoniaResponsive}
              // stagePadding={30}
            />
          </div>
        </section>
      );
    }
  }

  sponsorItems(sponsors, resource_path) {
    return sponsors.map((sponsor) => {
      let image = null;
      if (sponsor.logo) {
        image = (
          <img
            className="img-fluid"
            src={resource_path + sponsor.logo}
            width={120}
            alt={sponsor.title}
          />
        );
      }
      return (
        <div className="item p-3 text-center">
          {sponsor.link ? (
            <a href={sponsor.link} target="_blank" rel="noopener noreferrer">
              {image}
            </a>
          ) : (
            { image }
          )}
        </div>
      );
    });
  }

  sponsorResponsive = {
    0: {
      items: 1,
    },
    768: {
      items: 3,
    },
    1140: {
      items: 5,
    },
  };

  sponsorsSection(partners) {
    if (partners.data.length) {
      return (
        <section className={`content ${this.props.classes.sponsorSection}`}>
          <div className={this.props.theme.classConstant.CONTAINER_CLASS}>
            <MyCarousel
              items={this.sponsorItems(partners.data, partners.resource_path)}
              arrows={false}
              autoPlay={false}
              mouseDragEnabled={true}
              slideButton={this.slideButton}
              responsive={this.sponsorResponsive}
              // borderSpacing={30}
            />
          </div>
        </section>
      );
    }
  }

  render() {
    const { classes, theme, home, layout } = this.props;
    const classConstant = theme.classConstant;
    const slider = home.slider;
    const about = home.intro;
    const notice = home.notice;
    const program = home.program;
    const event = home.event;
    const testimonial = home.testimonial;
    const partner = home.partner;

    // console.log("Home:::", layout);

    const homeCarousel = slider.data.length ? (
      <HomeCarousel slides={slider} layout={layout} />
    ) : null;

    return (
      <Layout>
        {home.popup.data.length ? <PopUp popup={home.popup} /> : null}

        {homeCarousel}

        {this.scrollingNotice(notice.data)}

        {this.aboutUs(about)}

        {this.programs(program)}

        {notice.data.length || event.data.length ? (
          <section className={`content ${classes.newsSection} news-section`}>
            <div className={classConstant.CONTAINER_CLASS}>
              <div className="row justify-content-center">
                {this.noticeSection(notice)}
                {this.eventSection(event, layout)}
              </div>
            </div>
          </section>
        ) : null}

        {layout.profile.data.id == 24 ? (
          <section className="content">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-4 mb-3">
                  <h2 className="section-heading heading-ue">
                    Upcoming Events
                  </h2>
                  <iframe
                    title="Upcoming Events"
                    src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Asia%2FKolkata&showTz=0&showCalendars=0&showTabs=0&showPrint=0&showDate=0&showNav=0&showTitle=0&mode=AGENDA&src=a2FtYWxrY0BibmtzLmVkdS5ucA&src=Ym5rcy5lZHUubnBfYzZlZzdiaDlsbm8zNGQxY2tjam1kamtwMzBAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%237986CB&color=%23C0CA33"
                    width="w-100"
                    height="350"
                    frameBorder="0"
                    scrolling="no"
                  ></iframe>
                </div>
                <div className="col-md-6 col-lg-4 mb-3">
                  <h2 className="section-heading heading-fb">Facebook</h2>
                  <iframe
                    title="Facebook"
                    className="w-100"
                    src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fbnksofficial%2F&tabs=timeline&width=350&height=350&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=false&appId=1288602514609223"
                    height="350"
                    scrolling="no"
                    frameBorder="0"
                    allow="encrypted-media"
                  ></iframe>
                </div>
                <div className="col-md-12 col-lg-4 mb-3">
                  <h2 className="section-heading heading-yt">Youtube</h2>
                  <iframe
                    title="Youtube"
                    className="w-100"
                    height="350"
                    src="https://www.youtube.com/embed/uN9dYPZy9e0"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </div>
          </section>
        ) : null}

        {this.testimonialSection(testimonial)}

        {this.sponsorsSection(partner)}
      </Layout>
    );
  }

  componentWillMount() {
    this.props.getHomePageData();
  }
}

function mapStateToProps(state) {
  return {
    layout: state.layout,
    home: state.home,
  };
}

export default injectSheet(styles)(
  connect(mapStateToProps, { getHomePageData })(Home)
);
