import axios from 'axios';

class SchoolApi {
  base_url = '';
  headers = {};

  hostname =
    (window.location.hostname.indexOf('www.') && window.location.hostname) ||
    window.location.hostname.replace('www.', '');
  // hostname = "192.168.1.69";
  // hostname = "nedf.edu.np";
  // hostname = "uniquecoms.com";
  // hostname = "super@admin.com";
  hostname = 'bnks.edu.np';
  protocol = window.location.protocol;

  constructor() {
    this.base_url = `${this.protocol}//${this.hostname}/api`;
  }

  get(path) {
    const url = `${this.base_url}${path}`;
    return axios
      .get(url, { params: { client: this.hostname } })
      .then(function (response) {
        // console.log(response);
        return response.data;
      })
      .catch(function (error) {
        // console.log(error);
      });
  }

  create(path, data) {
    const url = `${this.base_url}${path}`;
    this.headers['Content-Type'] =
      'application/x-www-form-urlencoded;charset=UTF-8';

    return axios
      .post(url, {
        headers: this.headers,
        client: this.hostname,
        body: data,
      })
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        //console.log(error);
      });
  }
}

export default SchoolApi;
