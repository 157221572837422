import React from "react";

//component import
import PageTitle from "./common/PageTitle";
import SidebarNav from "./common/SidebarNav";

//asset import
// import Loader from "../../images/loaders/oval.svg";

const STUDENT_RECORD_TEMPLATE = ({
  page,
  breadCrumb,
  activeBreadCrumb,
  setActiveBreadCrumb
}) => {
  const data = page.data;

  let tableRows = (
    <tr className="text-center">
      <td colSpan="5">Loading...</td>
    </tr>
  );

  if (data.length) {
    tableRows = data.map((singleData, i) => (
      <tr key={i}>
        <td className="text-center">{i + 1}</td>
        <td>{singleData.batch}</td>
        <td>{singleData.title}</td>
        <td>{singleData.year}</td>
        <td className="text-center smnall">
          <a
            href={page.resource_path + singleData.file}
            className="btn btn-link"
            target="_blank"
          >
            View File
          </a>
        </td>
      </tr>
    ));
  } else {
    tableRows = (
      <tr className="text-center">
        <td colSpan="5">No data found.</td>
      </tr>
    );
  }

  return (
    <div className="row">
      <div className="col-md">
        <PageTitle title="Student Records" />
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th>S.N</th>
              <th>Batch</th>
              <th>Title</th>
              <th>year(A.D)</th>
              <th className="text-center">File(pdf)</th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
      </div>
      <SidebarNav
        breadCrumb={breadCrumb}
        activeBreadCrumb={activeBreadCrumb}
        setActiveBreadCrumb={setActiveBreadCrumb}
      />
    </div>
  );
};

export default STUDENT_RECORD_TEMPLATE;
