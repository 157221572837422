import React from "react";
import { Button } from "reactstrap";
import injectSheet from "react-jss";

import FA from "react-fontawesome";

const styles = {
  content: {
    background: "#eee",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 15
  }
};

const Blank = ({ classes }) => {
  return (
    <div className={`${classes.content} text-center`}>
      <h1 className="mb-4">Something went wrong!</h1>
      <p>Make sure you are connected to the internet. Click the button below to reload homepage.<br />If still issue exists, contact the system administrator.</p>
      <Button color="secondary" tag="a" href="/">
        <FA name="refresh mr-1" /> Reload Homepage
      </Button>
    </div>
  );
};

export default injectSheet(styles)(Blank);
