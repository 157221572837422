import React from "react";
import { Link } from "react-router-dom";

//react-jss
import injectSheet from "react-jss";

const style = theme => ({
  notice: {
    height: theme.scrollingNotice.height,
    lineHeight: theme.scrollingNotice.height + "px",
    color: theme.scrollingNotice.color,
    backgroundColor: theme.scrollingNotice.backgroundColor,
    "& a": {
      color: theme.scrollingNotice.color
    }
  },
  noticeContent: {
    height: theme.scrollingNotice.height,
    position: "relative",
    overflow: "hidden"
  },
  label: {
    position: "absolute",
    zIndex: 10,
    top: 0,
    left: 0
  },
  labelInner: {
    height: theme.scrollingNotice.height,
    display: "inline-block",
    padding: "0 20px",
    color: theme.scrollingNotice.label.color,
    fontSize: theme.scrollingNotice.label.fontSize,
    fontWeight: theme.scrollingNotice.label.fontWeight,
    backgroundColor: theme.scrollingNotice.label.backgroundColor,
    "&:after": {
      left: "100%",
      borderWidth: 12,
      borderleftColor: "#000",
      borderLeftColor: theme.scrollingNotice.label.backgroundColor,
      top: "calc(50% - 12px)"
    }
  }
});

const ScrollingNotice = ({ classes, theme, notices }) => {
  const classConstant = theme.classConstant;

  return (
    <div className={classes.notice}>
      <div className={classConstant.CONTAINER_CLASS}>
        <div className={classes.noticeContent}>
          <span className={classes.label}>
            <span className={`has-arrow ${classes.labelInner}`}>Notice</span>
          </span>
          <div className="notice-text">
            <ul className="list-inline mb-0">
              {notices.map(notice => {
                return (
                  <li className="list-inline-item" key={notice.id}>
                    <Link to={`/notice/${notice.slug}`}>{notice.title}</Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectSheet(style)(ScrollingNotice);
